html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.split-pane-divider {
  background: #000;
  width: 10px;
  cursor: ew-resize;
  height: 10px;
  cursor: ns-resize;
}
.button-group {
  padding-bottom: 1rem;
  font-family: Roboto, sans-serif;
  font-size: 18px;
}
.ag-root-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
.ag-side-bar {
  border-right: none;
  border-left: 1px solid #ccc;
}
.ag-header-cell-label {
  white-space: normal;
  text-overflow: clip;
  overflow-wrap: break-word;
}
.ag-theme-alpine {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
}

.flag-circle {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 0 8px rgba(0,0,0,0.25);
  margin-left: 10px;
  margin-right: 10px;
}

.weblab-status {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 0 8px rgba(0,0,0,0.25);
  margin-right: 10px;
}

.ag-theme-quartz .ag-header-cell-label {
  font-weight: bold;
}

.ag-theme-quartz .ag-header-cell-filtered {
  background-color: #1b6d85 !important;
  color: #fff !important;
}

.ag-theme-quartz .ag-popup, .ag-theme-quartz .ag-filter {
  width: 350px;
}

.help-center {
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 98.6%;
  background-color: #eeeee4; /* light olive */
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1); /* subtle shadow */
  flex-direction: column;
  border: 1px solid gray;
}

.help-search-box {
  padding: 10px;
  margin-bottom: 20px;
  width: calc(100% - 20px);
}

* {
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
